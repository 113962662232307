import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { useEffect, useState } from "react";
import Observable, { ZenObservable } from "zen-observable-ts";
import {
    ListDatumsByNodeIdTypeIdQuery,
    ListDatumsByNodeIdTypeIdQueryVariables,
    OnUpdateDatumByNodeIdSubscription,
} from "../../API";
import { listDatumsByNodeIdTypeId } from "../../graphql/queries";
import { onUpdateDatumByNodeId } from "../../graphql/subscriptions";
import { Datum } from "../../model/database";
import {
    SubscribeError,
    SubscribeEventData,
} from "../../model/database/lib/graphql";
import { Result } from "./result";

/**
 * 指定ノードの Datum レコードを取得します。
 *
 * 通常の `useDatabaseFind`/`useDatabaseFindGreedily` では値変更を購読していませ
 * ん。このフックを使う事で値の変更を購読できます。
 * (nodeId 毎に Subscription を作成します)
 *
 * @param nodeId ノード ID
 * @returns 該当ノードの Datum レコード群
 */
export function useDatums(nodeId?: string): Result<Datum[]> {
    const [result, setResult] = useState<Result<Datum[]>>({});

    useEffect(() => {
        let subscription: ZenObservable.Subscription;
        let unmounted = false;

        if (nodeId) {
            // datum のフェッチ
            const fetchData = async () => {
                const result = (await API.graphql(
                    graphqlOperation(listDatumsByNodeIdTypeId, {
                        nodeId,
                        limit: 1000, // とりあえず
                    } as ListDatumsByNodeIdTypeIdQueryVariables),
                )) as GraphQLResult<ListDatumsByNodeIdTypeIdQuery>;
                if (unmounted) {
                    return;
                }

                if (result.errors && result.errors.length > 0) {
                    setResult({ error: result.errors });
                }
                const items = result.data?.listDatumsByNodeIdTypeId
                    ?.items as Datum[];
                if (items) {
                    setResult({ data: items });
                }
            };
            fetchData().catch((error) => {
                if (unmounted) {
                    return;
                }
                console.error(error);
            });

            // datum のサブスクリプション
            subscription = (
                API.graphql(
                    graphqlOperation(onUpdateDatumByNodeId, {
                        nodeId,
                    }),
                ) as Observable<
                    SubscribeEventData<OnUpdateDatumByNodeIdSubscription>
                >
            ).subscribe(
                () => {
                    if (unmounted) {
                        return;
                    }
                    fetchData().catch((error) => {
                        if (unmounted) {
                            return;
                        }
                        console.error(error);
                    });
                },
                (error: SubscribeError) => {
                    if (unmounted) {
                        return;
                    }
                    console.error(error.error);
                },
            );
        }

        return () => {
            unmounted = true;
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [nodeId]);

    return result;
}
