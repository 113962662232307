import { RefObject, useEffect } from "react";

export namespace useMoveable {
    /**
     * {@link useMoveable} フックのパラメーター
     */
    export interface Parameters {
        /** 位置を設定する要素への参照 */
        containerRef: RefObject<HTMLElement>;
        /** ドラッグ可能にする要素への参照 */
        handleRef: RefObject<HTMLElement>;
    }
}

/**
 * 要素をドラッグ移動できるようにするフックです。
 *
 * `handleRef` をドラッグすると `containerRef` を移動します。
 * 例えば、ヘッダ要素を `handleRef` に、ルート要素を `containerRef` に渡すと良く
 * あるウィンドウの振る舞いになります。
 *
 * 移動には `containerRef` 要素の `transform` CSS プロパティを利用します。
 * `containerRef` 要素が別の目的で `transform` CSS プロパティを利用していると、
 * うまく動作しません。
 *
 * @param parameters パラメーター
 */
export function useMoveable(parameters: useMoveable.Parameters): void {
    const { containerRef, handleRef } = parameters;

    useEffect(() => {
        const handle = handleRef.current;
        if (!handle) {
            return undefined;
        }

        let dragging = false;
        let moving = false;
        let originX = 0;
        let originY = 0;
        let startX = 0;
        let startY = 0;
        let x = handle.clientLeft;
        let y = handle.clientTop;
        let oldX = x;
        let oldY = y;

        // 移動する
        function move(): void {
            const xi = Math.round(x);
            const yi = Math.round(y);
            if (xi === oldX && yi === oldY) {
                return;
            }

            const containerStyle = containerRef.current?.style;
            if (containerStyle === undefined) {
                return;
            }

            containerStyle.transform = `translate(${xi}px, ${yi}px)`;
            oldX = xi;
            oldY = yi;
        }

        // ドラッグ開始を処理する
        function onStart(event: PointerEvent): void {
            if (!event.isPrimary) {
                return;
            }

            dragging = true;
            moving = false;
            originX = x;
            originY = y;
            startX = event.clientX;
            startY = event.clientY;
        }

        // ドラッグを処理する
        function onMove(event: PointerEvent): void {
            if (!event.isPrimary || !dragging) {
                return;
            }

            // 移動幅を算出する (8px以上移動するまでは何もしない)
            const dx = event.clientX - startX;
            const dy = event.clientY - startY;
            moving ||= dx * dx + dy * dy >= 64;
            if (!moving) {
                return;
            }

            // 移動する
            x = originX + dx;
            y = originY + dy;
            move();
        }

        // ドラッグ完了を処理する
        function onEnd(event: PointerEvent): void {
            if (!event.isPrimary) {
                return;
            }

            dragging = false;
            moving = false;
            onResize();
        }

        // ウィンドウサイズ変更を処理する
        function onResize(): void {
            const rect = containerRef.current?.getBoundingClientRect();
            if (rect === undefined) {
                return;
            }

            x = Math.max(0, Math.min(window.innerWidth - rect.width, x));
            y = Math.max(0, Math.min(window.innerHeight - rect.height, y));
            move();
        }

        // イベント購読
        handle.addEventListener("pointerdown", onStart, { passive: true });
        window.addEventListener("pointermove", onMove, { passive: true });
        window.addEventListener("pointerup", onEnd, { passive: true });
        window.addEventListener("resize", onResize, { passive: true });
        return () => {
            handle.removeEventListener("pointerdown", onStart);
            window.removeEventListener("pointermove", onMove);
            window.removeEventListener("pointerup", onEnd);
            window.removeEventListener("resize", onResize);
        };
    }, [containerRef, handleRef]);
}
